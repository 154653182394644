/*
 * Custom Compponents
 * [NOTE]:
 * When create custom components, make sure it work without third party library,
 * so we can port it to other project easily.
 * Use known naming convension when possible like BEM, OOCSS, etc
 */

// Color Swatch

//  Cta
.cta {
    font-size: 1.2rem;
    padding-bottom: .5rem;
    color: $color-accent;
    border-bottom: 2px solid $black;

    &:hover {
        border-bottom: 2px solid $color-accent;
    }
}

.color-swatch {
    // @include contentWidth(padding);
    text-align: center;
    max-width: 768px;
    margin: 0 auto;
    padding-top: $space-lg * 4;
    padding-bottom: $space-lg * 4;

    h2 {
        border-bottom: 2px solid $color-accent;
        display: inline-block;
        padding-bottom: $space-sm;
        margin-bottom: $space-lg;
    }

    p {
        margin-bottom: $space-lg * 2;
    }
}

.list-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
        width: calc(100% / 4 - #{$space-md});

        @media (max-width: $tablet - 1) {
            width: calc(100% / 3);
        }

        @media (max-width: $mobile) {
            width: calc(100% / 2);
        }
    }

    picture {
        // border-radius: 50%;
        overflow: hidden;
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        margin-bottom: $space-sm;

        img {
            width: 100%;
        }
    }

    figcaption {
        font-size: 1.2rem;
        margin-bottom: $space-lg * 2;
    }
}

// Applications List

.applications {
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;

    // @media (max-width: $mobile) {
        padding-left: $space-md;
        padding-right: $space-md;
    // }

    &.single {
        max-width: $contentWidthLimit;

        li {
            align-items: flex-start;

            @media (max-width: $mobile) {
                margin-bottom: 0;
            }
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-lg * 4;
        align-items: center;

        @media (max-width: $mobile) {
            flex-direction: column;
        }

        & > * {
            width: calc(100% / 2 - 2rem);

            @media (max-width: $mobile) {
                width: 100%;
            }
        }

        &:nth-child(even),
        &.reverse {
            flex-direction: row-reverse;

            @media (max-width: $mobile) {
                flex-direction: column;
            }

        }
    }

    img {
        width: 100%;
    }



    h2 {
        font-size: 1.8rem;
        margin-bottom: $space-sm;
    }

    p {
        margin-bottom: $space-md * 2;
    }

    a {
        @extend .cta;
    }

}

// Quotes
#quotes {
    position: relative;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: $color-accent;
        opacity: .9;
        z-index: 1;
    }

    h2 {
        font-size: 2rem;
        text-align: center;
        margin: 2rem auto;
        padding-bottom: .5rem;
        color: $white;
        display: inline-block;
        position: relative;
        z-index: 2;
        text-transform: capitalize;

        &:after {
            content: '';
            width: 100%;
            max-width: 280px;
            display: block;
            position: absolute;
            height: 2px;
            background: $white;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        @media (max-width: $mobile) {
            margin: 1rem auto 2rem auto;
        }
    }

    li {
        list-style: none;
    }
    *[data-quotes] {
        z-index: 2;

        @media (max-width: $mobile) {
            height: 420px;
        }

        .quote {
            padding-left: $space-lg * 4;
            padding-right: $space-lg * 4;

            @media (max-width: 1024px - 1) {
                padding-left: $space-lg * 2;
                padding-right: $space-lg * 2;
            }

            @media (max-width: $mobile) {
                padding-left: 0;
                padding-right: 0;
            }
        }


        q {
            color: $white;
            border-color: $white;
            padding-right: 0;

        }
        .name, .city {
            color: $white;
            font-size: 120%;
        }

        .name {
            text-transform: initial;
        }

        .city {
            font-weight: 100;
        }
    }

    // span {
    //     &.prev, &.next {
    //         position: absolute;
    //         z-index: 2;
    //         &:after {
    //             content: '';
    //             display: block;
    //             width: 20px;
    //             height: 20px;
    //             border-top: 2px solid $white;
    //             border-right: 2px solid $white;
    //         }
    //     }

    //     &.prev {
    //         left: 0;
    //         &:after {
    //             transform: rotate(-135deg);
    //         }
    //     }
    //     &.next {
    //         right: 0;
    //         &:after {
    //             transform: rotate(45deg);
    //         }
    //     }
    // }


}

footer {
    .service-area {
        margin-left: 0;
        margin-right: 0;
        flex-wrap: nowrap;

        ul {
            margin-right: 40px;
        }
    }
}

.toll-free {
    background: $color-accent;
    color :$white;
    @include paddingY($space-lg);
    text-align: center;
    @include paddingX($space-md);

    h4 {
        font-weight: 100;
    }

    span {
        font-size: 1.8rem;
        font-weight: bold;

        @media (max-width: $tablet) {
            display: block;
            padding-top: $space-md;
        }
    }

    a {
        color: $white;
    }
}

.financing {
    display: block;
    @include paddingY($space-lg);
    @extend p;
    img {
        max-width: 100px;
    }
}

.lang-switcher {
    text-transform: uppercase;
}

// Labeled Image
.labeled-image {
    position: relative;

    &::before {
        content: attr(data-label);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: $space-md;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        background: rgba(0, 0, 0, .5);
        font-weight: bold;
        font-size: 1.5rem;
    }

    img {
        width: 100%;
    }
}

// Pricing
.section-price {

    @include paddingY($space-lg * 2);

    figure {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $space-md;
        font-weight: bold;

        & > * {
            width: 60px;
            text-align: center;
            color: $color-accent;
            font-size: 1.5rem;
        }
    }

    .title {
        padding-bottom: 0;
    }

    & > *:not(.title) {
        margin-bottom: $space-md * 2;
    }

    & > p {
        text-align: center;
        max-width: 472px;
        display: block;
        @include marginX(auto);

        &:last-child {
            max-width: 1024px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        p {
            text-align: center;
            margin-bottom: $space-md;
            line-height: 1;

            &:last-child {
                margin-bottom: 0;
                line-height: 1.4;
            }
        }

        & > * {
            width: calc((100% / 4) - #{$space-md * 4});

            @include paddingX($space-lg);
            @include paddingY($space-md * 2);
            @include box-shadow(1px, 1px, 4px, #999);

            @media (max-width: 1024px) {
                width: calc((100% / 2) - #{$space-md * 2});
                margin-bottom: $space-lg;
            }

            @media (max-width: 540px) {
                width: 100%;
            }
        }
    }

}
