// sizes
$space-sm: .5rem;
$space-md: 1rem;
$space-lg: 1.5rem;


// colors
$white: #fff;
$black: #000000;
$color-accent: #F67063;
$color-bg-green: #EFF5F0;

$color-btn-white-text: #9b9b9b;


// media query width
$mobile: 560px;
$tablet: 768px;
$desktop: 1024px;
$large-desktop: 1400px;