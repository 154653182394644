#quotes {
    background-color: $white;
    padding: ($space-md * 1.5) $space-md;

    * {
      color: $black;
    }

    .container {
      /* Used to position navigational buttons */
      position: relative;
    }

    h2 {
      display: block;
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: $space-md;
      max-width: 100%;
      width: 100%;
    }

    span.prev, span.next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 3rem;
    }

    span.prev {
      left: -1.5rem;
    }

    span.next {
      right: -1.5rem;
    }

    *[data-quotes] {
      position: relative;
      height: 400px;
      overflow: hidden;

      @media screen and (max-width: 1024px - 1) {
        height: 450px;
      }

      @media screen and (max-width: 768px - 1) {
        height: 400px;
      }

      @media screen and (max-width: 400px) {
        height: 600px;
      }

      .quote {
        transition: all ease-in-out 500ms;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 50%;
        left: -50%;
        padding: $space-md;
        vertical-align: top;

        @media screen and (max-width: 768px - 1) {
          left: -100%;
          width: 100%;
        }
      }

      .quote.active { left: 0; }

      .quote.next {
        left: 50%;
        @media screen and (max-width: 768px - 1) {
          left: 100%;
        }
      }

      .quote.no-transition { transition: none; }

      .quote.next.slideIn { left: 100%; }

      .quote.prev.slideIn { left: -100%; }

      .quote.outLeft { left: -100%; }

      .quote.outRight { left: 100%; }

      q {
        border-bottom: 1px solid $color-accent;
        display: block;
        font-size: 1.25rem;
        line-height: 1.35;
        padding-bottom: 2rem;
        padding-right: 3rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 768px - 1) {
          padding-right: 0;
        }

        &::before,
        &::after {
          content: none;
        }
      }

      .name,
      .city {
        display: block;
        font-size: 0.75rem;
        line-height: 1.5;
      }

      .name {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  div.ratings {
    background-color: $black;
    width: 100%;
    padding: 0.5rem;
    text-align: center;

    * {
      color: $white;

      &:hover {
        color: $color-accent;
      }
    }
  }
