
.btn-main, .btn-main-sm {
    background-image: none;
    background: $color-accent;
    text-transform: lowercase;
    font-size: 1.5rem;
    padding: 3px 20px;

    &::first-letter {
        text-transform: uppercase;
    }

    &.white {
        background: $white;
        color: $color-btn-white-text;
    }
}

.portfolio-block {
    &::before {
        background: $color-accent;
        opacity: 0;
    }
    &:hover {
        &::before {
            opacity: .7;
        }
    }
}

// Common

p {
    font-size: 1.2rem;
    color: $black;
    margin-bottom: $space-md * 2;
    &:empty {
        display: none;
    }
}

.section {
    padding: 80px 0;
}

//Logo
.navbar-brand {
    width: 160px;

    img {
        width: 150px;
    }
}

.navigation {
  position: relative;
  z-index: 20;

    .navbar-nav > li > a {
        color: $white;
        font-size: 1.3rem;
    }
}

.navbar-nav {
    @media (max-width: $mobile) {
        padding-top: 1.5rem;
    }
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-top: .8rem;
    padding-left: 2rem;

    @media (max-width: $mobile) {
        padding-bottom: 2.5rem;
    }

    @media (max-width: $tablet) {
        padding-left: 2rem;
    }
    @media (min-width: $desktop) {
        padding-left: 4rem;
    }
}

// Breadcrumbs
.breadcrumb {
    @include contentWidth(padding);
    margin-bottom: 0;

    @media (max-width: $contentWidthLimit) {
        padding-left: $space-md;
        padding-right: $space-md;
    }

    a {
        color: $color-accent;
    }
    // [FIXME]: responsive
    .breadcrumb-item {
        &::before {
            @media (max-width: $mobile) {
                padding-right: .2rem;
                padding-left: .2rem;
            }
        }
    }
}

footer {
    .logo {
        width: 150px;
    }
}

.top-footer {
    padding: 60px;
    color: $white;

    h3 {
        font-size: 1.5rem;
        text-transform: lowercase;
        font-weight: bold;
        &::first-letter {
            text-transform: uppercase;
        }
    }

    p, li {
        font-size: 1.2rem;
        line-height: 1.5;
        color: $white;
    }
}

.footer-bottom {
    @include contentWidth(padding);
    background: $white;
    display: flex;
    justify-content: space-between;

    i {
        color: red;
    }

    @media (max-width: $contentWidthLimit) {
        padding-left: $space-md;
        padding-right: $space-md;
    }


    @media (max-width: $mobile) {
        flex-direction: column;

        & > * {
            margin-bottom: $space-md;
        }
    }
    // [FIXME]: responsive
}

.service-list {

    .block {
        min-height: 280px;
        margin-bottom: $space-lg;
        @include box-shadow(1px, 1px, 4px, #999);

        p {
            color: $white;
            font-size: 1rem;
        }
    }
}

// Block list

.title {
    h1,
    h2 {
        display: inline-block;
        border-bottom: 2px solid $color-accent;
        padding-bottom: $space-md / 2;
        margin-bottom: $space-lg;
        text-transform: lowercase;
        font-size: 35px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    p {
        width: 60%;
    }
}
.list-avantage {
    img {
        width: 33px;
    }
}

.list-applications {
    img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: $space-md * 2;
    }

    .block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

// Contact BLock;
#contact {
    a {
        color: #000;
    }
}
.contact-meta-block {
    min-height: 300px;
    box-shadow: none;

    p, a {
        color: #999;
    }

    &:hover {
        @include box-shadow(1px, 1px, 4px, #999);
    }
}


// Pages

//Home
.hero-area {
    background: url('/images/hero.jpg');
    background-position-y: 60%;
    background-size: cover;
    padding: 60px 0 60px 0;

    @media (max-width: $mobile) {
        padding: 120px 0 40px 0;
    }

    position: relative;
    &::before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $black;
        opacity: .5;
        filter: contrast(2);
    }

    .logo {
        width: 180px;
        margin-bottom: $space-lg * 1.5;
    }

    .block {
        h1 {
            font-size: 2.2rem;
            margin-bottom: 1.8rem;
        }

        p {
            color: $white;
            font-size: 1.6rem;
        }
    }
}

#home-about h2 {
    padding-left: 3.5rem;
    &::before {
        background-image: url(/images/logo-accent.svg);
        background-repeat: no-repeat;
        height: 40px;
        top: 9px;
        left: -32px;
        width: 30%;
        background-color: transparent;
    }

    @media (max-width: 768px) {
        padding-left: 0;
    }
}

.single-page-header {
    background: url('/images/section-header-bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 0;
    padding: $space-md * 1 0 $space-md 0;

    .container {
        position: relative;
        z-index: 2;
    }

    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        background: $black;
        opacity: .6;
        filter: contrast(3);
        z-index: 1;
    }
}

// Contact
.contact-form #contact-submit {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
}
