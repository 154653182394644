.avis-legal {
  main {
    margin-top: 10rem;
  }


  #introduction {
    padding-top: 0;
  }
}

.legal-block {

    &.hidden { display: none; }

    ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 2rem;
    }

    li {
        margin-bottom: 0.5rem;
    }

    a { text-decoration: underline; }
}

nav.-legal {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

nav.-legal a {
    margin-inline: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    color: $color-accent !important; /* Use accent / link color */
    cursor: pointer;
    text-decoration: underline !important;
}

.document {

  padding-bottom: 4rem;

  & > * {
    margin-bottom: 1rem;
  }

  h1,
  h2 {
    font-weight: bold;
    &::after {
      display: none;
    }
  }

  h2 {
    margin-top: 2rem;
  }

  a {
    font-weight: 700;
    color: $color-accent;
  }

  ul {
    line-height: 1.5;
    font-weight: 100;
    list-style: disc;
    padding-left: 2rem;
  }

  nav {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 4rem;

    a {
      text-decoration: underline;
    }
  }
}


