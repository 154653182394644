$contentWidthLimit: 1111px;

@mixin contentWidth ($method) {
    #{$method}-left: calc((100% - #{$contentWidthLimit}) / 2);
    #{$method}-right: calc((100% - #{$contentWidthLimit}) / 2);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
  }

  @mixin paddingX ($size) {
    padding-left: $size;
    padding-right: $size;
  }

  @mixin paddingY ($size) {
    padding-top: $size;
    padding-bottom: $size;
  }

  @mixin marginX ($size) {
    margin-left: $size;
    margin-right: $size;
  }

  @mixin marginY ($size) {
    margin-top: $size;
    margin-bottom: $size;
  }